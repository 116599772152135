var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            { attrs: { sm: "12" } },
            [
              _c(
                "b-col",
                { staticClass: "px-0 text-center", attrs: { sm: "12" } },
                [
                  _c("qrcode-stream", {
                    staticStyle: { width: "70% !important", margin: "0 auto" },
                    attrs: { track: this.paintOutline },
                    on: { init: _vm.onInit, decode: _vm.onDecode },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.qrtext,
                          expression: "!qrtext",
                        },
                      ],
                      staticClass: "qrnoti",
                    },
                    [
                      _vm._v("QR코드를 스캔하시면"),
                      _c("br"),
                      _vm._v("해당업무로 이동할 수 있는 버튼이 생성됩니다."),
                    ]
                  ),
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.qrtext,
                        expression: "qrtext",
                      },
                    ],
                    staticStyle: { "margin-top": "20px", height: "40px" },
                    attrs: {
                      size: "xl",
                      label: _vm.qrtext,
                      dense: false,
                      icon: "security_update_good",
                      color: "amber-7",
                    },
                    on: {
                      btnClicked: function ($event) {
                        return _vm.linkGo(_vm.qrtextlink)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }